@tailwind base;
@tailwind components;
@tailwind utilities;

textarea:focus,
input:focus {
  outline: none;
}

ion-content,
ion-item,
ion-toolbar ion-input,
ion-header {
  --background: #f8f8f8;
}
ion-toolbar {
  --background: #f8f8f8;
}

@media (prefers-color-scheme: dark) {
  ion-content,
  ion-item,
  ion-toolbar,
  ion-input,
  ion-header{
    --background: #474747;
  }
}

@media (prefers-color-scheme: dark) {
  ion-tab-bar,
  ion-item{
    --background: #393939;
  }
}

input {
  background-color: white;
}

ion-content {
  --padding-top: 5px;
}

.app-menu {
  width: 300px;
}


.centered {
  object-fit: cover;
}

.orange-layer-position {
  width: calc(100% - 30px);
}

@media (min-width: 768px) {
  .orange-layer-position {
    width: calc(100% - 30px);
  }
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.wpwl-button-pay {
  color: black;
}
.swiper-button-next,
.swiper-button-prev {
  color: gray !important;
 --swiper-navigation-size: 15px !important;
  top: 10px !important;
  border: 1px solid gray !important;
  border-radius: 14px;
  padding: 12px;
}
