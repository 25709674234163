.loader {
  border: 10px solid #f8f8f8;
  border-top: 10px solid #f5b966;
  border-bottom: 10px solid #f5b966;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
